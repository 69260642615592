<script>
export default {
  computed: {
    filtersEnabled() {
      return (
        this.filters.site.length ||
        this.filters.category.length ||
        this.filters.color.length
      );
    },
  },
};
</script>
