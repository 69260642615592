import axios from "axios";
import { apiGatewayId } from "./constants";

async function getAvailable(all) {
  try {
    const response = await axios.get(
      `https://${apiGatewayId}.execute-api.eu-central-1.amazonaws.com/main/items`,
      { params: { all: all } }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export { getAvailable };
