<template>
  <v-app>
    <v-app-bar scroll-behavior="elevate">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-badge v-if="filtersEnabled" color="red">
           <template #badge>
      <v-icon color="white">mdi-filter</v-icon>
    </template>
      </v-badge>

      <v-app-bar-title>
        <swiper
          :modules="modules"
          :space-between="30"
          :centered-slides="true"
          :navigation="true"
          :autoplay="{
            delay: 4000,
            disableOnInteraction: false,
          }"
        >
          <swiper-slide>
            <div class="d-flex fill-height justify-center align-center">
              <img class="icon" src="/bot.jpg" />Перейти до&nbsp;
              <a href="https://t.me/FilamentDABot" target="_blank"> Бота </a>
            </div>
          </swiper-slide>
        </swiper>
      </v-app-bar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary>
      <Filters></Filters>
    </v-navigation-drawer>

    <v-main>
      <MainScreen />
    </v-main>
  </v-app>
</template>

<script>
import MainScreen from "@/components/MainScreen.vue";
import Filters from "@/components/Filters.vue";
import { useFiltersStore } from "@/store";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

export default {
  name: "App",
  components: {
    MainScreen,
    Filters,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  data: () => {
    return {
      filters: useFiltersStore(),
      drawer: false,
    };
  },
  mounted() {
    this.filters.$subscribe((mut, state) => {
      localStorage.setItem("filtersState", JSON.stringify(state));
    });
  },
  computed: {
    filtersEnabled() {
      return (
        this.filters.site.length ||
        this.filters.category.length ||
        this.filters.color.length
      );
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.v-main {
  margin-top:30px;
}
</style>
<style>
.icon {
  width: 36px;
  height: 36px;
}
</style>
