import { defineStore } from "pinia";

export const useFiltersStore = defineStore("filamentFilters", {
  state: () => {
    const state = localStorage.getItem('filtersState');
    if (!state) {
      return { site: [], category: [], color: [] }
    }
    return JSON.parse(state)
  }
});

export const useShowAll = defineStore("showAll", {
  state: () => ({ show: false }),
});
