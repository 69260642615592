<template>
  <div
    :style="{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }"
  >
    <div
      :style="{
        width: size + 'px',
        height: size + 'px',
        borderRadius: '50%',
        border: '2px solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: color,
      }"
      :class="{ rainbow: color == 'rainbow' }"
    ></div>
    <div v-if="!hideTitle" class="color-desc ml-2">
      {{ english_to_ukrainian[color] }}
    </div>
  </div>
</template>

<script>
const english_to_ukrainian = {
  black: "чорний",
  white: "білий",
  green: "зелений",
  red: "червоний",
  grey: "сірий",
  transp: "прозорий",
  brown: "коричневий",
  orange: "помаранчевий",
  yellow: "жовтий",
  khaki: "хакі",
  blue: "синій",
  violet: "фіолетовий",
  lightgreen: "світло-зелений",
  lightblue: "світло-синій",
  pink: "рожевий",
  pearl: "перлинний",
  turquoise: "бірюзовий",
  beige: "бежевий",
  metal: "металевий",
  rainbow: "веселка",
  "no-color": "натурал",
  "???": "незрозумілий"
};

export default {
  name: "FilamentIcon",
  data: () => {
    return { english_to_ukrainian: english_to_ukrainian };
  },
  props: {
    size: {
      type: [Number, String],
      default: 32,
    },
    color: {
      type: String,
      default: "currentColor",
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>

<style scoped>
.rainbow {
  border-radius: 50%;
  background: linear-gradient(
    to right,
    #ff0000,
    #ff7f00,
    #ffff00,
    #00ff00,
    #0000ff,
    #4b0082,
    #9400d3
  );
}
.color-desc {
  width: 20px;
}
</style>
