import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import { isDev } from "./constants";

import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import VueGtag from "vue-gtag";

const pinia = createPinia();

const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App).use(vuetify).use(pinia);
if (!isDev) {
  app.use(VueGtag, {
    config: { id: "G-T70VCQK7T8" },
  });
}
app.mount("#app");
