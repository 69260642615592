<template>
  <div>
    <div class="screen-title flex">
      <div class="d-flex fill-height justify-center align-center">
        Перевірено {{ diff(lastUpdatedTime) }}, оброблено
        {{ processedItems }} позицій, Показано {{ filtered }} з
        {{ items.length }}
      </div>
    </div>
    <v-data-table
      items-per-page="-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      :custom-filter="customFilter"
      :search="search"
      :sort-by="sortBy"
      filter-keys="color"
      class="px-2"
    >
      <template v-slot:item.color="{ value }">
        <FilamentIcon :color="value"></FilamentIcon>
      </template>
      <template v-slot:item.title="{ value, item }">
        <div class="text-start">
          <a :href="formatURL(item.site, item.url)" target="_blank">
            {{ value }}</a
          >
        </div>
      </template>
      <template v-slot:item.site="{ value }">
        <div class="text-start">
          <v-tooltip :text="value">
            <template v-slot:activator="{ props }">
              <img
                class="site-icon"
                :src="'/logos/' + siteLogo[value]"
                :alt="value"
                v-bind="props"
              />
            </template>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.price="{ value, item }"> {{ value }} ₴ </template>
      <template v-slot:loading>
        <v-skeleton-loader type="table-row@4"></v-skeleton-loader>
      </template>
      <template v-slot:bottom> </template>
    </v-data-table>
  </div>
</template>
<script>
import { getAvailable } from "@/api";
import { useFiltersStore, useShowAll } from "@/store";
import FilamentIcon from "@/components/FilamentIcon.vue";
import BaseMixin from "@/mixins/Base.vue";
import { formatDistanceToNow } from "date-fns";
import { uk } from "date-fns/locale";
import { siteLogo } from "@/constants";

export default {
  name: "MainScreen",
  components: {
    FilamentIcon,
  },
  mixins: [BaseMixin],
  setup() {
    return {
      siteLogo: siteLogo,
      headers: [
        { title: "Колір", value: "color", align: "center", width: 140 },
        {
          title: "Категорія",
          value: "category",
          sortable: true,
          align: "center",
          filterable: false,
          width: 10,
        },
        {
          title: "Сайт",
          value: "site",
          sortable: true,
          align: "start",
          width: 10,
        },
        { title: "Назва", value: "title", align: "start", minWidth: 650 },
        {
          title: "Вартість",
          align: "center",
          children: [
            { title: "Ціна за кг", value: "pricePerKg", sortable: true },
            { title: "Ціна", value: "price", sortable: true },
            { title: "Вага", value: "weight", sortable: true },
          ],
        },
      ],
      sortBy: [{ key: "pricePerKg", order: "asc" }],
    };
  },
  data: () => {
    return {
      search: "",
      filters: useFiltersStore(),
      showAll: useShowAll(),
      items: [],
      loading: false,
      lastUpdatedTime: undefined,
      processedItems: undefined,
      filtered: 0,
    };
  },
  methods: {
    formatURL(site, url){
      return (new URL(url, "https://" + site)).href
    },
    diff(date) {
      if (!date) {
        return "???";
      }
      return formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: uk,
      });
    },
    pricePerKg(item) {
      try {
        return (parseInt(item.price) / parseFloat(item.weight)).toFixed(1);
      } catch (error) {
        return "???";
      }
    },
    customFilter(value, query, item) {
      const filtered =
        (this.filters.site.length == 0 ||
          this.filters.site.indexOf(item.raw.site) != -1) &&
        (this.filters.category.length == 0 ||
          this.filters.category.indexOf(item.raw.category) != -1) &&
        (this.filters.color.length == 0 ||
          this.filters.color.indexOf(item.raw.color) != -1);
      if (filtered) {
        this.filtered++;
      }
      return filtered;
    },
    filtersUpdated() {
      this.filtered = 0;
      if (this.filtersEnabled) {
        this.search = "1";
      } else {
        this.search = "";
      }
    },
    async updateItems() {
      this.loading = true;
      const resp = await getAvailable(this.showAll.show);
      this.loading = false;
      if (this.filtersEnabled) {
        this.filtered = 0;
      } else {
        this.filtered = resp.items.length;
      }
      this.items = resp.items.map((item) => {
        return { ...item, pricePerKg: this.pricePerKg(item) };
      });
      this.lastUpdatedTime = resp.updated_time;
      this.processedItems = resp.processed;
    },
    async showAllUpdated() {
      this.updateItems();
    },
  },
  async mounted() {
    await this.updateItems();
    this.filters.$subscribe(this.filtersUpdated);
    this.showAll.$subscribe(this.showAllUpdated);
    if (this.filtersEnabled) {
      this.search = "1";
    }
  },
};
</script>
<style scoped>
.v-table {
  background-color: transparent;
}
.text-start {
  text-align: left !important;
}

.screen-title {
  height: 20px;
}
</style>
